const PasteImg = {
  paste(event){
      let data =[];
      let items = (event.clipboardData || event.originalEvent.clipboardData).items;
      console.log(JSON.stringify(items)); // will give you the mime types
      for (let index in items) {
        let item = items[index];
        if (item.kind === 'file') {
          let blob = item.getAsFile();
          let reader = new FileReader();
          reader.onload = function(event){
            document.getElementById("pasteImgBox").src = event.target.result;
            data[index] = event.target.result;
          };
          reader.readAsDataURL(blob);
        }
      }
      console.log(JSON.stringify(data));
      return JSON.stringify(data);
    }
}

  export default PasteImg;

import React from "react";
import "./catalogeItem.css";

class CatalogeItem2 extends React.Component{
  constructor(props) {
    super(props);
    this.state =  {

    };
    this.doesFileExist = this.doesFileExist.bind(this);

  }


doesFileExist(urlToFile){
    var xhr = new XMLHttpRequest();
    xhr.open('HEAD', urlToFile, false);
    try{
      xhr.send();
    }catch(e){
      console.log(e);
    }
    if (xhr.status === 404) {
        // console.log("Image doesn't exist");
        return 'red';
    } else {
        // console.log("Image exists");
        return 'green';
    }
  }


  render(){
    return (
      <tr className="CatalogeItem2"
      style={{backgroundColor:this.doesFileExist(`./catalogeImg/${this.props.catalogeItem.imgSrc}.png`)}}>
          <td>
            <div style={{cursor: "pointer"}} onClick={()=>this.props.edit(this.props.catalogeItem.no)} id={this.props.catalogeItem.no}>Edit {this.props.catalogeItem.no}</div>
          </td>
          <td>{this.props.catalogeItem.des}</td>
          <td>{this.props.catalogeItem.imgSrc}</td>
          <td>{this.props.catalogeItem.cat.map((x)=>`${x}, `)}</td>
          <td >
            <div style={{cursor: "pointer"}} onClick={()=>this.props.del(this.props.catalogeItem.no)} id={this.props.catalogeItem.no}>X</div>
          </td>
      </tr>
    )
  }
}

export default CatalogeItem2;

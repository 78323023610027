import React from "react";
import ImageZoom from 'react-medium-image-zoom';
import "./catalogeItem.css";
import CategoryItem from "../categoryItem/categoryItem";
// import SearchBar from "../searchBar/searchBar";

class CatalogeItem extends React.Component{
  constructor(props) {
    super(props);

    this._handleZoom = this._handleZoom.bind(this)
    this._click = this._click.bind(this)
  }
  _handleZoom() {
      document.getElementById(
      this.props.catalogeItem.imgSrc
      ).insertAdjacentHTML(
        'beforebegin', `<div class="imgTitleText">${this.props.catalogeItem.imgSrc}</div>`)
        try{
          document.getElementsByClassName("sticked")[0].style.zIndex = "1";
        }catch(e){
          console.error(e);
        }
}
  _click(){
    // App.SearchBar.setState({searchWord1:this.props.catalogeItem.no});
    console.log(`_click ${this.props.catalogeItem.no}`);
    console.log(`_click ${this.object}`);
    document.getElementById("SearchField").value = this.props.catalogeItem.no;
    document.getElementById("submitSearch").click();

  }
  render(){
    return (
      <div className="CatalogeItem">
        <div className="image-container">
        <ImageZoom
          overlay={{
            title: this.props.catalogeItem.imgSrc,
          }}
          defaultStyles={{
            image: {cursor: 'pointer'},
            zoomImage: {cursor: 'pointer'},
            overlay: {backgroundColor: 'rgba(0,0,0,0.5)'}
          }}
          image={{
            src: `./catalogeImg/${this.props.catalogeItem.imgSrc}.png`,
            alt: this.props.catalogeItem.imgSrc,
            className: 'img',
          }}
          zoomImage={{
            src: `./catalogeImg/${this.props.catalogeItem.imgSrc}.png`,
            alt: this.props.catalogeItem.imgSrc,
            title: this.props.catalogeItem.imgSrc,
            id: this.props.catalogeItem.imgSrc,
          }}
          onZoom={this._handleZoom}
          />
        </div>
        <div className="but"
          onClick={(e)=>{this._click(e)}}>
        <h2>{this.props.catalogeItem.no}</h2>
        </div>
          <h3>{this.props.catalogeItem.des}</h3>
        <div className="CatalogeItem-information">
        {/*<h3>Category</h3>*/}
          <div className="CatalogeItem-reviews">
            {
              this.props.catalogeItem.cat.map(categoryItem=>{
                if(categoryItem!==undefined){
                  return <CategoryItem categoryItem={categoryItem} key={categoryItem+Math.round(Math.random() * 10000)}/>;
                }
                return null;
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

export default CatalogeItem;

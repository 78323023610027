import React from "react";
import "./catalogeList.css";
import CatalogeItem2 from "../catalogeItem/catalogeItem2";

class CatalogeList2 extends React.Component {
  constructor(props){
    super(props);
    this.setSort = this.setSort.bind(this)
  }

setSort(num){
  if(this.props.setSort==="Descending"){
    return num*-1;
  }else{
    return num;
  }
}

  render() {
    return(
      <table className="grid-item itemTbl CatalogeList2" >
      <tbody>
      <tr className="CatalogeItem2">
          <th>Number</th>
          <th>Description</th>
          <th>imgSrc</th>
          <th>Category</th>
      </tr>
        {
          this.props.catalogeItems.sort(
            (a,b) => (a.no > b.no) ? this.setSort(1) : ((b.no > a.no) ? this.setSort(-1) : 0)
          ).map(catalogeItem=>{
          // ).map(catalogeItem=>{
            // if(catalogeItem.no.indexOf(document.getElementById("filter").value>-1)){
              return <CatalogeItem2 edit={this.props.edit} del={this.props.del} catalogeItem={catalogeItem} key={catalogeItem.no+catalogeItem.des}/>;
            // };
            // console.log(catalogeItem);
            // if(catalogeItem.imgSrc.length>0){
            // }else{return null}
          })
        }
        </tbody>
      </table>
    )
  }
}
export default CatalogeList2;

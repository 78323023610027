import React from "react";
import "./newItem.css";
import CatalogeList2 from '../catalogeList/catalogeList2';
import PasteImg from "../pasteImg/pasteImg.js";

class NewItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      catalogeItems: [],
      selectedFile: null,
      imgdata1: ""
    };

    this.add = this.add.bind(this);
    this.dlimg = this.dlimg.bind(this);
    this.nextPrj = this.nextPrj.bind(this);
    this.nextCus = this.nextCus.bind(this);
    this.nextPrt = this.nextPrt.bind(this);
    this.nextAsm = this.nextAsm.bind(this);
    this.nextRev = this.nextRev.bind(this);
    this.generate = this.generate.bind(this);
    this.dl = this.dl.bind(this);
    this.openAddWindow = this.openAddWindow.bind(this);
    this.paste = this.paste.bind(this);
    this.del = this.del.bind(this);
    this.edit = this.edit.bind(this);

  }
  openAddWindow() {
    console.log("openAddWindow");
    document.getElementById("NewItem").style.display = "none";
    document.getElementById("infoBox").style.display = "grid";
    document.getElementsByClassName("SearchBar")[0].style.display = "none";
    document.body.parentElement.style.backgroundColor = "dimgrey";
  }
  nextPrj() {
    let val = document.getElementById("no").value;
    val = val.split("-");
    ++val[0];
    if (val[0] < 10) {
      val[0] = "00" + val[0];
    } else if (val[0] < 100) {
      val[0] = "0" + val[0];
    }
    val = val.join("-");
    document.getElementById("no").value = val;
  }
  nextCus() {
    let val = document.getElementById("no").value;
    val = val.split("-");
    ++val[1];
    if (val[1] < 10) {
      val[1] = "00" + val[1];
    } else if (val[1] < 100) {
      val[1] = "0" + val[1];
    }
    val = val.join("-");
    document.getElementById("no").value = val;
  }
  nextPrt() {
    let val = document.getElementById("no").value;
    val = val.split("-");
    console.log(val[2]);
    let type = "";
    if (isNaN(val[2])) {
      type = val[2][val[2].length - 1];
      val[2] = val[2].slice(0, -1);
      ++val[2];
    } else {
      ++val[2];
    }
    if (val[2] < 10) {
      val[2] = "00" + val[2];
    } else if (val[2] < 100) {
      val[2] = "0" + val[2];
    }
    val[2] = val[2] + type;
    val = val.join("-");
    document.getElementById("no").value = val;
  }
  nextAsm() {
    let val = document.getElementById("no").value;
    val = val.split("-");
    console.log(val[2]);
    if (isNaN(val[2])) {
      let type = val[2][val[2].length - 1];
      if (type === "A" || type === "a") {
        type = "P";
      } else if (type === "P" || type === "p") {
        type = "A";
      } else {}
      val[2] = val[2].slice(0, -1);
      val[2] = val[2] + type;
    } else {}
    val = val.join("-");
    document.getElementById("no").value = val;
  }
  nextRev() {
    let val = document.getElementById("no").value;
    val = val.split("-");
    ++val[3];
    if (val[3] < 10) {
      val[3] = "0" + val[3];

    }
    val = val.join("-");
    document.getElementById("no").value = val;
  }
  add() {
    // var elm = function(id) {
    //   return document.getElementById(id).value.length > 0;
    // }
    var elmV = function(id) {
      return document.getElementById(id);
    }
    if (elmV("no").value.length>0) {
      // if(elm("des")&&elm("no")&&elm("srcW")){
      try {
        let data = this.state.catalogeItems;
        let newData = {
          no: elmV("no").value,
          des: elmV("des").value,
          pdf: "",
          imgSrc: elmV("no").value,
          'Billede 2': "",
          cat: elmV("srcW").value.split(" ")
        }
        let isThere = false;
        // console.log(newData);
        data.forEach((val) => {
          if (val.no === newData.no) {
            isThere = true;
            data.splice(data.indexOf(val),1);
            data.push(newData);
            console.log(data.indexOf(val));
            console.log(data);
            document.getElementById("add").style.transition = "all 0.3s";
            document.getElementById("add").style.backgroundColor = "green";
            setTimeout(function() {
              document.getElementById("add").style.backgroundColor = "dimgrey";
              elmV("no").value = "";
              elmV("des").value = "";
              elmV("srcW").value = "";
            }, 2000);
          // } else {
          }
        })
        if (!isThere) {
          data.push(newData);
        }
        isThere = false;
        this.setState({
          catalogeItems: data
        });
      } catch (e) {
        console.log("error");
        console.log(e);
      }
    } else {
      console.log("fields is not filled out.");
    }
  }
  dlimg() {
    try {
      let dataUrl;
      dataUrl = document.getElementById("pasteImgBox").src;
      dataUrl = dataUrl.split(',');
      let base64 = dataUrl[1];
      let mime = dataUrl[0].match(/:(.*?);/)[1];
      let bin = atob(base64);
      let length = bin.length;
      let buf = new ArrayBuffer(length);
      let arr = new Uint8Array(buf);
      bin
        .split('')
        .forEach((e, i) => arr[i] = e.charCodeAt(0));
      let f = new File([buf], document.getElementById("no").value + '.png', {
        type: mime
      }); // note: [buf]
      let blobUrl = URL.createObjectURL(f);
      let link = document.createElement("a");
      link.href = blobUrl;
      link.download = document.getElementById("no").value;
      link.innerHTML = "Download file.";
      link.id = "lnk";
      link.onClick = ".parentNode.removeChild(parentNode)";
      document.getElementById("box").appendChild(link);
      document.getElementById("lnk").click();
      document.getElementById("box").removeChild(document.getElementById("lnk"));
    } catch (e) {
      console.log("error");
      // console.log(e);
    }

  }
  generate() {
    console.log(this.state.catalogeItems.length);
    if (this.state.catalogeItems.length === 0) {
      const url = './database/cataloge.json';
      fetch(url).then(res => {
        if (res.ok) {
          return res.json().then((data) => {
            if (true) {
              return data;
            }
          })
        }
      }).then(data1 => {
        console.log(data1)
        this.setState({
          catalogeItems: data1
        })
      });
    } else {
      const url = './database/cataloge.json';
      fetch(url).then(res => {
        if (res.ok) {
          return res.json().then((data) => {
            if (true) {
              return data;
            }
          })
        }
      }).then(data2 => {
        // console.log(data2);
        // console.log(this.state.catalogeItems);
        // this.state.catalogeItems.forEach((val) => {
        //
        //   data2.push(val);
        // })
        console.log(data2);
        this.setState({
          catalogeItems: data2
        });
      });
    }
  }
  dl() {
    console.log(this.state.catalogeItems);
    let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.state.catalogeItems));
    let downloadAnchorNode = document.createElement('a');
    // console.log(dataStr);
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", "cataloge.json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }
  fileSelect(e) {
    this.setState({
      selectedFile: e.target.files[0]
    })
  }
  del(no) {
    console.log("del", no);
    this.state.catalogeItems.forEach((val) => {
      if (val.no === no) {
        let index = this.state.catalogeItems.indexOf(val);
        let ar = this.state.catalogeItems;
        ar.splice(index, 1);
        this.setState({
          catalogeItems: ar
        });
      }
    })
  }

  edit(no) {
    console.log("edit", no);
    let doc = function(id){
      try{
        return document.getElementById(id);
      }catch(e){
        console.log(e);
      }
    }
    this.state.catalogeItems.forEach((val) => {
      if (val.no === no) {
        let i = this.state.catalogeItems.indexOf(val);
        let ar = this.state.catalogeItems;
        console.log(ar[i]);
        doc("no").value = ar[i].no;
        doc("des").value = ar[i].des;
        doc("srcW").value = "";
        ar[i].cat.forEach((val)=>{
          doc("srcW").value = doc("srcW").value + val +" ";
        });
      }
    })
  }
  paste(e) {
    console.log("paste");
    let dataUrl = PasteImg.paste(e);
    this.setState({
      imgdata1: dataUrl
    });
    console.log(this.state.imgdata1);

  }
  close() {
    console.log("close");
    document.getElementById("NewItem").style.display = "";
    document.getElementById("infoBox").style.display = "none";
    document.getElementsByClassName("SearchBar")[0].style.display = "flex";
    document.body.parentElement.style.backgroundColor = "rgb(80, 80, 80)";

  }
  render() {
    return (
      <div id = "box" >

      <div id = "NewItem"
      onClick = {
        this.openAddWindow
      } >
      <a id="plus">
      +
      </a>
      </div>
        <div id = "infoBox" onPaste = {event => {this.paste(event)}} >
          <h1 className = "grid-item item1" > Add New Catalogue Item < /h1>
          <input className = "grid-item AddInput"     id = "no"     placeholder = "Number (& image name)" / >
          <input onPaste = { event => {this.paste(event)}} className = "grid-item AddInput"  id = "des"  placeholder = "Description" / >
          <input className = "grid-item AddInput" id = "srcW"  placeholder = "Category / Search Words " / >
          {/*<input className = "grid-item AddInput" id = "filter"  placeholder = "Filter for list " / >*/}
          <div className = "grid-item"id = "next" >
          <button type = "buttom" text = "" className = "button" id = "nextPrj"        onClick = {this.nextPrj} > +Proj < /button>
          <button type = "buttom" text = "" className = "button" id = "nextCus"        onClick = {this.nextCus} > +Cust < /button>
          <button type = "buttom"        text = "" className = "button" id = "nextPrt"        onClick = {this.nextPrt} > +P < /button>
          <button type = "buttom" text = "" className = "button" id = "nextAsm" onClick = {this.nextAsm} > /A</button >
          <button type = "buttom"      text = ""      className = "grid-item button"      id = "nextRev"
          onClick = {this.nextRev} > +Rev </button>
        </div >
        <button type = "buttom" text = "" className = "grid-item button" id = "add"
        onClick = {this.add}> Add/Save Item < /button>
        <button type = "buttom" text = "" className = "grid-item button" id = "dlimg"
        onClick = {this.dlimg} > Download img < /button>
        <button type = "buttom" text = "Generate JSON" className = "grid-item button" id = "generate"
        onClick = {this.generate} > Generate JSON < /button>
        <button type = "buttom" text = "Download JSON" className = "grid-item button" id = "dl"
        onClick = {this.dl} > Download JSON < /button>
        <button type = "buttom" text = "" className = "grid-item button" id = "close"
        onClick = {this.close} > Close < /button>
        <img className = "grid-item itemImg"
        style = {{
            display: "block",
            width: "auto",
            height: "200px"
          }} id = "pasteImgBox" src = "" alt = "" / >
        <CatalogeList2
        del = {this.del}
        edit = {this.edit}
        setSort = {this.state.sortBy}
        catalogeItems = {this.state.catalogeItems}
        />

        </div>
      </div>


    )
  }
}

export default NewItem;

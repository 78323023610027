import React from "react";
import "./categoryItem.css";
// import SearchBar from "../searchBar/searchBar";

class CategoryItem extends React.Component{
  render(){
    return (
      <div className="CatergoryItem" >
            {`${this.props.categoryItem}`}<span>&nbsp;</span>
        </div>
    )
  }
}

export default CategoryItem;

import React, {
  Component
} from 'react';
import './App.css';
import CatalogeList from "./components/catalogeList/catalogeList";
import SearchBar from "./components/searchBar/searchBar";
import NewItem from "./components/newItem/newItem";
import SearchData from "./util/searchData.js";
import Sticky from '@wicked_query/react-sticky';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      offsetSecond: 0,
      catalogeItems: [],
      sortBy: "",
      imgdata: ""
    };
    this.searchYelp = this.searchYelp.bind(this)
    this.sort2 = this.sort2.bind(this)
  }

  searchYelp(word1, sortBy) {
    SearchData.search(word1, sortBy).then(data => {
      if (data) {
        this.setState({
          catalogeItems: data
        })
      };
      // console.log("searchYelp",this.state.catalogeItems);
    });
  }
  sort2(sortBy) {
    this.setState({
      sortBy: sortBy
    });
  }


  render() {
    return (
      <div  className = "App" >

      {/*<StickyContainer>*/}
      < NewItem imgdata = {this.state.imgdata} newItem = {this.newItem} catalogeItems = {this.state.catalogeItems}/>
      {/*<img src="logo-soltech technical solutions_grey.svg" alt="Soltech Aps"/>*/}

        <Sticky subscribe={(props) => (this.setState({ offset: props.height }))}>
            <SearchBar searchYelp = {this.searchYelp} setSort = {this.sort2}/>

        </Sticky>
        <CatalogeList setSort = {this.state.sortBy} catalogeItems = {this.state.catalogeItems}/>
      {/*</StickyContainer>*/}
      </div >
    );
  }
}

export default App;

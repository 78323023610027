import React from "react";
import "./catalogeList.css";
import CatalogeItem from "../catalogeItem/catalogeItem";

class CatalogeList extends React.Component {
  constructor(props){
    super(props);
    this.setSort = this.setSort.bind(this)
  }

setSort(num){
  if(this.props.setSort==="Descending"){
    return num*-1;
  }else{
    return num;
  }
}

  render() {
    return(
      <div className="CatalogeList" >
        {
          this.props.catalogeItems.sort((a,b) => (a.no > b.no) ? this.setSort(1) : ((b.no > a.no) ? this.setSort(-1) : 0)).map(catalogeItem=>{
            if(catalogeItem.imgSrc.length>0){
              return <CatalogeItem catalogeItem={catalogeItem} key={catalogeItem.no+catalogeItem.des}/>;
            }else{return null}
          })
        }
      </div>
    )
  }
}
export default CatalogeList;

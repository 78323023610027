import React from "react";
import "./searchBar.css";

class SearchBar extends React.Component{
  constructor(props){
    super(props);
    this.state ={
      term      : "",
      location  : "",
      sortBy    : "Ascending",
      timer     : 0
    };

    this.handleSortByChange     = this.handleSortByChange.bind(this);
    this.searchWord1            = this.searchWord1.bind(this);
    this.handleSearch           = this.handleSearch.bind(this);
    this.sort1                  = this.sort1.bind(this);
    this.time                  = this.time.bind(this);
    this.clear                  = this.clear.bind(this);

    this.sortByOptions={
      "Ascending":"Ascending",
      "Descending":"Descending",
      // "Most Reviewed":"review_count"
    };
    // console.log("SearchBar",this.state,this.props);

  }

  getSortByClass(sortByOption){
    if (sortByOption === this.state.sortBy){
      return "active";
    }else{
      return ""
    }
  }

  handleSortByChange(sortByOption){
    console.log(sortByOption.target.innerHTML);

    this.setState({
        sortBy : this.sortByOptions[sortByOption.target.innerHTML]
    })

  }
  searchWord1(event){
      // console.log(event.target.value);
      this.setState({
        searchWord1 : event.target.value
    })
  }
  handleSearch(event){
    console.log("handleSearch");
    this.setState({
      searchWord1 : document.getElementById("SearchField").value})
    this.props.searchYelp(document.getElementById("SearchField").value,this.state.sortBy);
    event.preventDefault();
    if(document.getElementById("SearchField").value.length > 0){
      document.getElementById("SearchBar-clear").style.display = "flex";
    }else{
      document.getElementById("SearchBar-clear").style.display = "none";
    }
  }


  renderSortByOptions(){

      return Object.keys(this.sortByOptions).map(sortByOption=>{
      let sortByOptionValue = this.sortByOptions[sortByOption];
      return (<li
                  className={this.getSortByClass(sortByOptionValue)}
                  key={sortByOptionValue}
                  onClick={this.handleSortByChange}>
                {sortByOption}
              </li>);
    });
  }
  sort1(){
      this.props.setSort(this.state.sortBy)
    }
  time(){
      clearTimeout (this.state.timer);
      this.setState({timer : setTimeout(()=>{
        document.getElementById("submitSearch").click()
      }, 200)});
    };
  clear(){
    document.getElementById("SearchField").value = null;
  };
  render(){
    return(
        <div className="SearchBar" >
        {/*<div className="SearchBar-sort-options" >
          <ul>
            {this.renderSortByOptions()}
          </ul>
        </div>*/}
        <div className="SearchBar-fields">
          <input
          id="SearchField"
          onChange={this.searchWord1}
          placeholder="Search"
          onKeyDown={()=>this.time()}
           />
           <input
           id="SearchBar-clear"
           value="X"
           type="button"
           onClick={(e)=>{this.clear();this.handleSearch(e)}}

           />
           <div className="SearchBar-submit" >
             <a href="#top" id="submitSearch"
             onClick={(e)=>{this.handleSearch(e);this.sort1()}}
             >Let´s Go</a>
           </div>
        </div>

      </div>
    )
  }
}

export default SearchBar;

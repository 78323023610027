const SearchData = {
  search(word1,sortBy){
    console.log("searchData",word1,sortBy);
    const url = './database/cataloge.json';
    return fetch(url).then(res=>{
      if (res.ok){
        return res.json().then((data)=>{
        if(word1===undefined|| word1 === ""){
          return data;
        }
        function low(str) {
          return str.toLowerCase()
        }
        let items = [];
        data.forEach(function(catItem){
        word1.split(" ").forEach(function (e) {
          function func() {
              if (word1.trim().length > 0 && e.length > 0) {
                if ((catItem.no !== undefined && low(catItem.no).indexOf(low(e)) > -1) ||
                   (catItem.des !== undefined && low(catItem.des).indexOf(low(e)) > -1) ) {
                  return true;
                }
                if(catItem.cat){
                  var rtnTrue = false;
                  catItem.cat.forEach(function(category){
                    if (category !== undefined && low(category).indexOf(low(e)) > -1) {
                      rtnTrue = true;
                    }
                  })
                  if (rtnTrue) return true;
                }
              }
            }
            if(func()){
              if(items.indexOf(catItem) === -1){
                items.push(catItem);
              }
            }
          });
        });
        return items;
      })
    }
  })
}

}
export default SearchData;
